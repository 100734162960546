import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import PopularProperty from "../components/popularProperty";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div class="slider_area">
        <div class="single_slider test   d-flex align-items-center slider_bg_1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-10 offset-xl-1">
                        <div class="slider_text text-center justify-content-center">
                            <h3>云尚地产</h3>
                            <p>住宅买卖，商业地产，全美置业，房屋托管​</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="popular_property">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="section_title mb-40 text-center">
                        <h3>Popular Properties</h3>
                    </div>
                </div>
            </div>
            <PopularProperty></PopularProperty>
            {/* <div class="row">
                <div class="col-xl-12">
                    <div class="more_property_btn text-center">
                        <a href="#" class="boxed-btn3-line">More Properties</a>
                    </div>
                </div>
            </div> */}

        </div>
    </div>

    <div class="testimonial_area overlay ">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="testmonial_active owl-carousel">
                        <div class="single_carousel">
                                <div class="single_testmonial text-center">
                                        <div class="quote">
                                            <img src="img/svg_icon/quote.svg" alt="" />
                                        </div>
                                        <p>云尚地产是投资者的长期合作伙伴，提供完善的房屋托管，让投资者高枕无忧。 </p>
                                        <div class="testmonial_author">
                                            <div class="thumb">
                                                    <img class="testimonial_img" src="img/team/pengfei.jpg" alt="" />
                                            </div>
                                            <h3>Pengfei Zhang</h3>
                                            <span>Business Owner</span>
                                        </div>
                                    </div>
                        </div>
    
            
                    </div>
                </div>
            </div>
        </div>
    </div>



  </Layout>
)

export default IndexPage
